<template>
  <!-- 应用订单报表页面 -->
  <div>
    <!-- 文字 -->
    <div class="text">
      <h4>应用订单报表</h4>
    </div>
    <div class="theQuery">
      <div class="theQuery-Tow">
        <p>按应用名称查询</p>
        <el-input v-model="Call" placeholder="请输入内容" prefix-icon="el-icon-star-off"></el-input>
        应用金额汇总:0
      </div>
      <div class="theQuery-Tow">
        <p>按支付类型查询</p>
        <el-select v-model="value" placeholder="请选择">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
        </el-select>
      </div>
      <div class="theQuery-Tow">
        <p>按手机号查询</p>
        <el-input v-model="ID" placeholder="请输入手机号" prefix-icon="el-icon-mobile-phone"></el-input>
      </div>
      <div class="theQuery-Tow">
        <p>按状态查询</p>
        <el-select v-model="approve" placeholder="请选择">
          <el-option v-for="item in attestation" :key="item.value" :label="item.label" :value="item.value"> </el-option>
        </el-select>
      </div>
      <el-button type="primary" icon="el-icon-search" style="height: 40px; margin-top: 52px" @click="doFilter">查询</el-button>
      <el-button type="primary" icon="el-icon-refresh" style="height: 40px; margin-top: 52px; margin-left: 120px" @click="doFilter"
        >导出</el-button
      >
    </div>
    <!-- 内容区域 -->
    <div style="margin-top: 80px">
      <el-table :data="data" border style="width: 100%">
        <el-table-column prop="tokenName" label="姓名" width="" align="center"> </el-table-column>
        <el-table-column prop="tokenEnglish" label="用户手机号" width="" align="center"> </el-table-column>
        <el-table-column prop="tokenNum" label="应用订单号" width="" align="center"> </el-table-column>
        <el-table-column prop="gross" label="应用名称" width="" align="center"> </el-table-column>
        <el-table-column prop="gross" label="应用金额" width="" align="center"> </el-table-column>
        <el-table-column prop="gross" label="支付方式" width="" align="center"> </el-table-column>
        <el-table-column prop="gross" label="应用对应数字通证" width="" align="center"> </el-table-column>
        <el-table-column prop="gross" label="下单时间" width="" align="center"> </el-table-column>
        <el-table-column prop="gross" label="状态" width="" align="center"> </el-table-column>
        <el-table-column prop="gross" label="原因" width="" align="center"> </el-table-column>
        <el-table-column prop="gross" label="操作人" width="" align="center"> </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">查看</el-button>
            <el-button size="mini" type="danger" @click="handleDelete">下线</el-button>
            <el-button size="mini" type="primary" @click="handleDelete">重新审核</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 分页 -->
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage4"
        :page-sizes="[10, 15, 20, 30]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>


<script>
export default {
  name: '项目订单报表'
}
</script>

<style lang="scss" scoped>
.text {
  h4 {
    margin: 10px;
    color: #409eff;
  }
}
.theQuery {
  height: 70px;
  display: flex;
  margin-left: 30px;
  .theQuery-Tow {
    width: 200px;
    height: 70px;
    margin-right: 45px;
  }
}
</style>